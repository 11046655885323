<template>
    <b-table
            ref="refUserListTable"
            :items="items"
            :small="true"
            responsive
            :fields="columns"
            primary-key="id"
            show-empty
            empty-text="اطلاعاتی یافت نشد"
            class="text-nowrap"
            style="min-height : 235px"
    >
        <template #cell(coin)="data">
            <b-media vertical-align="center">
                <template #aside>
                    <b-avatar
                            size="32"
                            :src="require(`@/assets/images/Coins/${data.item.coin}.png`)"
                            :variant="`light-info`"
                    />
                </template>
                <b-link
                        class="font-weight-bold d-block text-nowrap"
                >
                    {{ $coinLabel[data.item.coin] }}
                </b-link>
                <small class="text-muted">{{ $coinUnit[data.item.coin] }} {{data.item.tokenType && data.item.tokenType!=='UNKNOWN' && ` (${data.item.tokenType})`}}</small>
            </b-media>
        </template>

        <template #cell(transferFee)="{item}">
            <CryptoCurrencyField :item="item" target="transferFee" label="کارمزد"/>
        </template>
        <template #cell(minWithdrawAmount)="{item}">
            <CryptoCurrencyField :item="item" target="minWithdrawAmount" label="حداقل برداشت"/>
        </template>
        <template #cell(maxWithdrawAmount)="{item}">
            <CryptoCurrencyField :item="item" target="maxWithdrawAmount" label="حداکثر برداشت"/>
        </template>
        <template #cell(minAmount)="{item}">
            <CryptoCurrencyField :item="item" target="minAmount" label="حداقل سفارش"/>
        </template>
        <template #cell(maxAmount)="{item}">
            <CryptoCurrencyField :item="item" target="maxAmount" label="حداکثر سفارش"/>
        </template>
        <template #cell(creditCardFee)="{item}">
            <CryptoCurrencyField :item="item" target="creditCardFee" label="کارمزد کارت ها"/>
        </template>

    </b-table>
</template>

<script>
    import {
        BAvatar,
        BLink,
        BMedia,
        BTable
    } from "bootstrap-vue";
    import CryptoCurrencyField from "@/views/CryptoCurrency/CryptoCurrencyTableField";

    export default {
        name: "CryptoCurrencyTable",
        props:['items','columns'],
        components: {
            CryptoCurrencyField,
            BTable,
            BMedia,
            BAvatar,
            BLink,
        },
        data(){
            return{
                dir: false,
                loading: false,
                edit: false,
                type: false
            }
        },
        methods:{
            dataEntered(coin, e) {
                console.log(coin,e)
                this.edit=false
                // this.data[coin] = this.$S2N(this.$toLocal(e)) || 0
                // this.$forceUpdate()
            },
        }
    }
</script>

<style scoped>

</style>